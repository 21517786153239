import React, { useState, useEffect } from "react";
import {
  initializeApp,
  getApps,
  getApp,
} from "firebase/app";
import { getDoc } from "firebase/firestore";
import {
  getFirestore,
  collection,
  onSnapshot,
  query,
  where,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  listAll,
  deleteObject,
} from "firebase/storage";
import HeaderI from "./Components/NavBarPro";
import { firebaseConfig } from "../../firebase/credenciales";

import "../css/table.css";

// Inicializar Firebase
const app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApp();
const db = getFirestore(app);
const storage = getStorage(app);

// Lista de correos electrónicos permitidos
const correosPermitidos = ["hectorleivagamboa0741@gmail.com", "bayron7310@gmail.com"];

// Nuevo componente para el modal de eliminación
const DeleteModal = ({ isOpen, onClose, onDelete }) => {
  return isOpen ? (
    <div className="delete-modal">
      <p>¿Estás seguro que quieres eliminar este producto?</p>
      <button onClick={onDelete}>Eliminar</button>
      <button onClick={onClose}>Cancelar</button>
    </div>
  ) : null;
};

function Tabla() {
  const [data, setData] = useState([]);
  const [usuarioAutenticado, setUsuarioAutenticado] = useState(null);
  const [editedData, setEditedData] = useState({});
  const [editedArchivo, setEditedArchivo] = useState(null);
  const [facturaURL, setFacturaURL] = useState(null);
  const [archivosActualizados, setArchivosActualizados] = useState(0);
  const [statusFilter, setStatusFilter] = useState("");
  const [editedDolares, setEditedDolares] = useState(null);

  // Nuevo estado para el modal de eliminación
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  useEffect(() => {
    document.body.classList.add("body-tabla");
    return () => {
      document.body.classList.remove("body-tabla");
    };
  }, []);

  useEffect(() => {
    const auth = getAuth(app);
    const unsubscribeAuth = onAuthStateChanged(auth, (usuario) => {
      setUsuarioAutenticado(usuario);
    });
    return () => unsubscribeAuth();
  }, [app]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const collectionRef = collection(db, "productos");
        let queryRef;

        if (
          usuarioAutenticado &&
          correosPermitidos.includes(usuarioAutenticado.email)
        ) {
          queryRef = collectionRef;
        } else if (usuarioAutenticado?.email) {
          queryRef = query(
            collectionRef,
            where("correoActual", "==", usuarioAutenticado.email)
          );
        } else {
          console.error(
            "Error: usuarioAutenticado o usuarioAutenticado.email es undefined",
            usuarioAutenticado
          );
          return;
        }

        const unsubscribe = onSnapshot(queryRef, (snapshot) => {
          const newData = snapshot.docs.map((doc) => ({
            id: doc.id,
            fecha: doc.data().Fecha,
            ...doc.data(),
            dolares: doc.data().dolares || null,
          }));
          setData(newData);
          setArchivosActualizados((prev) => prev + 1);
        });

        return () => unsubscribe();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [db, usuarioAutenticado, archivosActualizados]);

  const handleDownload = async (id, archivo) => {
    try {
      if (archivo) {
        const archivoUrl = await getDownloadURL(
          ref(storage, `Factura/${id}/${archivo}`)
        );
        window.open(archivoUrl, "_blank");
      }
    } catch (error) {
      console.error("Error descargando factura:", error);
    }
  };

  const handleEditClick = async (id) => {
    const editedItem = data.find((item) => item.id === id);
    setEditedData(editedItem);
    setEditedDolares(editedItem.dolares);

    if (editedItem.archivo) {
      const archivoUrl = await getDownloadURL(
        ref(storage, `Factura/${editedItem.id}/${editedItem.archivo}`)
      );
      setFacturaURL(archivoUrl);
    }

    // Mostrar el modal de eliminación
    // Comenta o elimina la siguiente línea
    // setIsDeleteModalOpen(true);
    setItemToDelete(editedItem);
  };

  const handleCancelEdit = () => {
    setEditedData({});
    setEditedArchivo(null);
    setFacturaURL(null);
    setEditedDolares(null);
  };

  const handleSaveEdit = async () => {
    try {
      const databaseID = editedData.id;

      const docRef = doc(db, "productos", editedData.id);
      const facturaFolderRef = ref(storage, `Factura/${databaseID}`);

      await updateDoc(docRef, {
        Status: editedData.Status,
        Tramite: parseInt(editedData.Tramite, 10) || 0,
        RS: editedData.RS,
        archivo: editedArchivo && editedArchivo.name ? editedArchivo.name : (editedData.archivo || ""),
        fecha: editedData.fecha,
        dolares: editedDolares !== null ? parseInt(editedDolares, 10) : null,
      });

      if (editedArchivo && correosPermitidos.includes(usuarioAutenticado.email)) {
        const archivoRef = ref(facturaFolderRef, editedArchivo.name);
        await uploadBytes(archivoRef, editedArchivo);

        const nuevaFacturaURL = await getDownloadURL(archivoRef);
        setFacturaURL(nuevaFacturaURL);
      }

      setEditedData({});
      setEditedArchivo(null);
      setEditedDolares(null);
    } catch (error) {
      console.error("Error actualizando datos:", error);
    }
  };

  const handleDelete = async () => {
    try {
      if (itemToDelete) {
        // Obtener la referencia al documento en Firestore
        const docRef = doc(db, "productos", itemToDelete.id);
  
        // Obtener la data del documento antes de eliminarlo
        const docData = (await getDoc(docRef)).data();
  
        // Eliminar el documento de Firestore
        await deleteDoc(docRef);
  
        // Obtener la referencia a la carpeta en Storage (Factura)
        const facturaFolderRef = ref(storage, `Factura/${itemToDelete.id}`);
  
        // Verificar si la referencia a la carpeta en Storage es válida
        if (facturaFolderRef) {
          // Listar los archivos en la carpeta de Factura en Storage
          const facturaFileList = await listAll(facturaFolderRef);
  
          // Verificar si la referencia de Storage es válida y si hay archivos
          if (facturaFileList && facturaFileList.items) {
            // Eliminar cada archivo de la carpeta de Factura en Storage
            await Promise.all(facturaFileList.items.map((file) => deleteObject(file.ref)));
          }
  
          // Eliminar la carpeta de Factura en Storage
          await deleteObject(facturaFolderRef);
        }
  
        // Verificar y eliminar la carpeta en Storage (Documentos)
        if (docData && docData.nombreProducto) {
          const documentosFolderRef = ref(storage, `Documentos/${docData.nombreProducto}`);
  
          // Verificar si la referencia a la carpeta en Storage es válida
          if (documentosFolderRef) {
            await deleteObject(documentosFolderRef);
          }
        }
  
        // Cerrar el modal y realizar cualquier otra acción necesaria después de la eliminación
        setIsDeleteModalOpen(false);
        setArchivosActualizados((prev) => prev + 1);
      }
    } catch (error) {
      console.error("Error eliminando producto:", error);
    }
  };
  
  
  
  

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
  };

  const handleStatusHeaderClick = () => {
    setStatusFilter("");
  };

  return (
    <div className="Hola">
      <HeaderI />
      <div className="filtro-container">
        <span>Filtrar por Status:</span>
        <select value={statusFilter} onChange={handleStatusFilterChange}>
          <option value="">Todos</option>
          <option value="Completado">Completos</option>
          <option value="En Proceso">En Proceso</option>
          <option value="Pendiente">Pendiente</option>
          <option value="No Pagado">No Pagado</option>
        </select>
      </div>
      <div className="table-container">
        <table className="table-Tabla">
          <thead>
            <tr>
              <th onClick={handleStatusHeaderClick}>Producto</th>
              <th onClick={handleStatusHeaderClick}>Status</th>
              <th>Factura</th>
              {correosPermitidos.includes(usuarioAutenticado?.email) && <th>Correo</th>}
              <th>#Tramite</th>
              <th>#RS</th>
              {correosPermitidos.includes(usuarioAutenticado?.email) && <th>Dólares</th>}
              <th className="redondear-Tabla">Vence</th>
              {correosPermitidos.includes(usuarioAutenticado?.email) && <th>Acciones</th>}
            </tr>
          </thead>
          <tbody>
            {data
              .filter((item) =>
                statusFilter ? item.Status === statusFilter : true
              )
              .map((item) => (
                <tr key={item.id}>
                  <td>{item.nombreProducto}</td>
                  <td>
                    {editedData.id === item.id ? (
                      <select
                        value={editedData.Status}
                        onChange={(e) =>
                          setEditedData({
                            ...editedData,
                            Status: e.target.value,
                          })
                        }
                      >
                        <option value="No pagado">No pagado</option>
                        <option value="En Proceso">En Proceso</option>
                        <option value="Completado">Completado</option>
                        <option value="Pendiente">Pendiente</option>
                      </select>
                    ) : (
                      item.Status
                    )}
                  </td>
                  <td>
                    {editedData.id === item.id ? (
                      <>
                        {correosPermitidos.includes(usuarioAutenticado?.email) && (
                          <input
                            type="file"
                            onChange={(e) =>
                              setEditedArchivo(e.target.files[0])
                            }
                          />
                        )}
                        {facturaURL ? (
                          <button onClick={() => handleDownload(item.id, editedArchivo.name)}>
                            Descargar factura
                          </button>
                        ) : (
                          "No hay factura"
                        )}
                      </>
                    ) : item.archivo ? (
                      <button onClick={() => handleDownload(item.id, item.archivo)}>
                        Descargar factura
                      </button>
                    ) : (
                      "No hay factura"
                    )}
                  </td>
                  {correosPermitidos.includes(usuarioAutenticado?.email) && (
                    <td>{item.correoActual}</td>
                  )}
                  <td>
                    {editedData.id === item.id ? (
                      <input
                        type="number"
                        value={editedData.Tramite}
                        onChange={(e) =>
                          setEditedData({
                            ...editedData,
                            Tramite: e.target.value,
                          })
                        }
                      />
                    ) : item.Tramite === 0 ? (
                      "-"
                    ) : (
                      item.Tramite
                    )}
                  </td>
                  <td>
                    {editedData.id === item.id ? (
                      <input
                        type="text"
                        value={editedData.RS}
                        onChange={(e) =>
                          setEditedData({
                            ...editedData,
                            RS: e.target.value,
                          })
                        }
                      />
                    ) : item.RS === 0 ? (
                      "-"
                    ) : (
                      item.RS
                    )}
                  </td>
                  {correosPermitidos.includes(usuarioAutenticado?.email) && (
                    <td>
                    {editedData.id === item.id ? (
                      <input
                        type="number"
                        value={editedDolares}
                        onChange={(e) => setEditedDolares(e.target.value)}
                      />
                    ) : item.dolares || item.dolares === 0 ? (
                      item.dolares
                    ) : (
                      "-"
                    )}
                  </td>
                  )}
                  <td>
                    {editedData.id === item.id ? (
                      <input
                        type="date"
                        value={editedData.fecha}
                        onChange={(e) =>
                          setEditedData({
                            ...editedData,
                            fecha: e.target.value,
                          })
                        }
                      />
                    ) : item.fecha && (
                      new Date(item.fecha).toLocaleDateString("es-ES", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                      })
                    )}
                  </td>
                  
                  {correosPermitidos.includes(usuarioAutenticado?.email) && (
                    <td>
                      {editedData.id === item.id ? (
                        <>
                          <button onClick={handleSaveEdit}>Guardar</button>
                          <button onClick={handleCancelEdit}>Cancelar</button>
                          <button
                            onClick={() => setIsDeleteModalOpen(true)}
                            style={{ color: "red" }}
                          >
                            Eliminar
                          </button>
                        </>
                      ) : (
                        <button onClick={() => handleEditClick(item.id)}>
                          Editar
                        </button>
                      )}
                    </td>
                  )}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {/* Modal de eliminación */}
      <DeleteModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={handleDelete}
      />
    </div>
  );
}

export default Tabla;
