import React, { useState, useEffect } from "react";
import { auth } from "../../../firebase/credenciales"; // Asegúrate de importar la instancia de autenticación desde tu archivo firebase.js
import "./quimicos.css";
import { Link } from "react-router-dom";

import Fondo from '../img/Cosmeticos.jpg'
import ProductosQuimicos from "./componenteQuimico.jsx";



const App = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      setUser(authUser);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const descriptions = [
    { text: <strong>Descripción:</strong>, strongText: "", totalText:" Los productos cosméticos son aquellos destinados a ser aplicados en el cuerpo humano con el fin de limpiarlo, perfumarlo, modificar su apariencia, protegerlo o mantenerlo en buen estado. Incluyen productos como cremas, lociones, maquillajes, entre otros.", customClass: "" },
    { text: <strong>Precio del Registro:</strong>, strongText: "", totalText: "$430", customClass: "textClass-Quimica" },
    { text: <strong></strong>, strongText: "", totalText: "Este precio incluye: ", customClass: "textClass-Quimica" },
    { text: "• Honorarios del profesional", strongText: "", totalText: "", customClass: "textClass-Quimica" },
    { text: "• Pago del registro ante el Ministerio de Salud", strongText: "", totalText: "", customClass: "textClass-Quimica" },
    { text: "• Pago de refrendo", strongText: "", totalText: "", customClass: "textClass-Quimica" },
    { text: "• Impuestos de valor agregado", strongText: "", totalText: "", customClass: "textClass-Quimica" }
  ];

  return (
    <ProductosQuimicos 
      backgroundImage={Fondo} 
      title="Registro de productos Cosméticos" 
      buttonText="Volver a costos y servicios" 
      descriptions={descriptions}
      imgClass='imgClass-Cosmeticos'
    />
  );
};


export default App;
