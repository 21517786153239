import React, { useState, useEffect } from "react";
import { auth } from "../../../../firebase/credenciales";
import NavbarPro from "../../Components/NavBarPro";
import Navbar from "../../Components/NavBarLite";
import "./regencia.css";
import { Link, useLocation } from "react-router-dom"; // Importa useLocation
import Boton from '../../Components/Boton'

import Fondo from '../../img/Regencia.jpg'

import { ReactComponent as SVGregresar } from "../../icon/newIcon/Regresar.svg";
import { ReactComponent as SVGrbm } from "../../icon/newIcon/RBM.svg";
import { ReactComponent as SVGretmp } from "../../icon/newIcon/RETMP.svg";
import { ReactComponent as SVGcrne } from "../../icon/newIcon/CRNE.svg";
import { ReactComponent as SVGriq } from "../../icon/newIcon/RIQ.svg";

const ServicioCard = ({ to, svg: SvgIcon, title }) => {
  return (
    <div className="box-regencia">
      <Link to={to} className="link-servicio">
        <SvgIcon className="svg-regencia" />
        <p>{title}</p>
      </Link>
    </div>
  );
};

const App = () => {
  const [user, setUser] = useState(null);
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const location = useLocation(); // Obtener la ubicación actual

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      setUser(authUser);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    // Verificar si la página anterior era "/servicios"
    if (location.state?.from === "/servicios") {
      setShowDisclaimer(true);
    }
  }, [location]);

  const handleDisclaimerClick = () => {
    setShowDisclaimer(false);
  };

  return (
    <div className="body-regencia">
      {user ? <NavbarPro /> : <Navbar />}
      <div className="contenedor-regencia">
        <div className="img-regencia">
          <img src={Fondo} alt="" className="fondo-regencia" />
          <h1>Regencia</h1>
        </div>

        <div className="Cartas-regencia">
          <ServicioCard
            to="/servicios"
            svg={SVGregresar}
            title="Volver a costos y servicios"
          />

          <ServicioCard
            to="/regenciaRBM"
            svg={SVGrbm}
            title="Regencia Básica Mensual"
          />

          <ServicioCard
            to="/regenciaRIQ"
            svg={SVGriq}
            title="Regencia para Industrias Químicas"
          />

          <div></div>

          <ServicioCard
            to="/regenciaRETMP"
            svg={SVGretmp}
            title="Regencia Especializada para Transporte de Materiales Peligrosos"
          />

          <ServicioCard
            to="/regenciaCRNE"
            svg={SVGcrne}
            title="Consultoría y Regencia para Nuevas Empresas"
          />
        </div>
      </div>
      <Boton />
    </div>
  );
};

export default App;
