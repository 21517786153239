import React, { useState, useEffect } from "react";
import { auth } from "../../../../firebase/credenciales"; // Asegúrate de importar la instancia de autenticación desde tu archivo firebase.js
import "../quimicos.css";
import { Link } from "react-router-dom";

import Fondo from '../../img/BPM.jpg'
import ProductosQuimicos from "../componenteQuimico.jsx";



const App = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      setUser(authUser);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const descriptions = [
    { text: "Este paquete está orientado a empresas que ya cuentan con BPM implementadas y requieren un seguimiento continuo para asegurar el cumplimiento y realizar mejoras.", customClass: "" },
    { text: <strong>• Visitas mensuales de regencia para monitoreo</strong>, strongText: "", totalText:" (4 horas/mes a $115/hora): $460", customClass: "textClass-Quimica" },
    { text: <strong>• Auditoría de procesos y detección de no conformidades:</strong>, strongText: "", totalText:" $200", customClass: "textClass-Quimica" },
    { text: <strong>• Elaboración de informes trimestrales de cumplimiento:</strong>, strongText: "", totalText: " $150", customClass: "textClass-Quimica" },
    { text: <strong>• Capacitación y actualización del personal en BPM</strong>, strongText: "", totalText: " (3 horas cada trimestre a $50/hora): $150", customClass: "textClass-Quimica" },
    { text: <strong>• Total paquete: $960 </strong>, strongText: "", totalText: "", customClass: "textClass-Quimica" },
    { text: <strong></strong>, strongText: "", totalText: "Nota: Esto es una sugerencia de paquete, podemos cotizar un servicio que se adapte a sus necesitas y que se adecue a su presupuesto", customClass: "texto-notas" }
    ];

  return (
    <ProductosQuimicos 
      backgroundImage={Fondo} 
      title="Monitoreo y Mantenimiento de BPM" 
      buttonText="Regencia" 
      descriptions={descriptions}
      redirectTo="/BPM"
      imgClass="imgClass-BPM"
    />
  );
};


export default App;
