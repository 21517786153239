import React, { useState, useEffect, useRef } from "react";
import { auth } from "../../firebase/credenciales"; // Asegúrate de importar la instancia de autenticación desde tu archivo firebase.js
import NavbarPro from "./Components/NavBarPro";
import Navbar from "./Components/NavBarLite";
import "../css/cotizar.css";
import emailjs from 'emailjs-com';
import fondoInicio from "./../jsx/img/Consulta.jpg";
import { useNavigate } from 'react-router-dom';  // Importar useNavigate de react-router-dom

const App = () => {
  const [user, setUser] = useState(null);
  const [warningMessage, setWarningMessage] = useState('');
  const [showWarning, setShowWarning] = useState(false);
  const form = useRef();
  const navigate = useNavigate();  // Crear una instancia del hook useNavigate

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        // El usuario está autenticado
        setUser(authUser);
      } else {
        // El usuario no está autenticado
        setUser(null);
      }
    });

    // Limpia el efecto cuando el componente se desmonta
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    document.body.classList.add("body-cotizar");
    return () => {
      document.body.classList.remove("body-cotizar");
    };
  }, []);

  const validateForm = () => {
    const formElements = form.current.elements;
    for (let i = 0; i < formElements.length; i++) {
      if (formElements[i].tagName === 'INPUT' || formElements[i].tagName === 'TEXTAREA') {
        if (!formElements[i].value.trim()) {
          return false;
        }
      }
    }
    return true;
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      setWarningMessage('Todos los campos deben estar llenos.');
      setTimeout(() => setShowWarning(true), 1);
      setTimeout(() => setShowWarning(false), 3000); // Ocultar el mensaje después de 3 segundos
      return;
    }

    emailjs.sendForm('service_ltjulnk', 'template_b61mgfc', form.current, 'HWxqW1vU-6IMWTdG6')
      .then((result) => {
          console.log(result.text);
          form.current.reset(); // Limpiar el formulario después de enviar el correo
          setWarningMessage(''); // Limpiar el mensaje de advertencia
          setShowWarning(false); // Ocultar el mensaje de advertencia
          
          // Redirigir a la página de gracias
          navigate('/graciaspage'); // Redirige al usuario a /graciaspage
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <div>
      {user ? <NavbarPro /> : <Navbar />}
      <div className="parent-cotizar">
        <div className="child-cotizar">
          <h1 className="titulo">Cotizar</h1> 
          <p>Estamos listos para brindarte un servicio a la medida de tus necesidades. Completa el formulario con la información requerida y nos pondremos en contacto contigo lo antes posible para ofrecerte una cotización personalizada. Nuestro equipo está comprometido en ofrecer soluciones rápidas, eficientes y ajustadas a tus requerimientos.</p>
        </div>
        <div className="child-cotizar">
          <div className="form-cotizar">
            <p>Dejenos sus datos para poder comunicarnos</p>
            <form ref={form} onSubmit={sendEmail} className="formClass">
              <input type="text" id="nombre" name="nombre" placeholder="Nombre" />
              <input type="text" id="empresa" name="empresa" placeholder="Empresa" />
              <input type="text" id="email" name="email" placeholder="Email" />
              <input type="text" id="telefono" name="telefono" placeholder="Telefono" />
              <textarea type="text" id="mensaje" name="mensaje" placeholder="Escriba tu mensaje" className="mensaje"/>
              <input type="submit" value="Enviar consulta"/>
            </form>
          </div>
        </div>
      </div>
      {warningMessage && (
        <div className={`warning-message ${showWarning ? 'show' : ''}`}>
          {warningMessage}
        </div>
      )}
    </div>
  );
};

export default App;
