import React, { useEffect } from "react";
import '../css/error.css';
import { Link } from "react-router-dom";


function Error() {
  useEffect(() => {
    // Al montar el componente, agrega la clase al body
    document.body.classList.add("error-body");
    // Al desmontar el componente, quita la clase del body
    return () => {
      document.body.classList.remove("error-body");
    };
  }, []); // El array vacío asegura que el efecto se ejecute solo una vez al montar y desmontar el componente

  return (
    <div className="error-body">
        
      <div className="circle"></div>
        <div className="oval"></div>
        <div className="oval"></div>
        <div className="oval"></div>

        <Link className="ERROR" to="/">Error 404</Link>
    </div>
  );
}

export default Error;
