import React, { useRef, useState, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { initializeApp, getApps, getApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import svg from "../svg/logo.svg";
import logOut from "../../../functions/logOut";
import { firebaseConfig } from "../../../firebase/credenciales";
import "./NavBar.css";

const app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApp();


function NavbarPro() {
  const navRef = useRef();
  const navigate = useNavigate();
  const [usuarioAutenticado, setUsuarioAutenticado] = useState(null);
  const correosPermitidos = ["hectorleivagamboa0741@gmail.com", "bayron7310@gmail.com","hectorleivagamboa741@gmail.com"];


  useEffect(() => {
    const auth = getAuth(app);
    const unsubscribeAuth = onAuthStateChanged(auth, (usuario) => {
      setUsuarioAutenticado(usuario);
    });
    return () => unsubscribeAuth();
  }, [app]);

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  async function logOutHome(e) {
    e.preventDefault();

    try {
      // Llama a la función logOut para cerrar sesión
      await logOut();

      // Después de cerrar sesión, espera un segundo y redirige a la página principal
      setTimeout(() => {
        navigate("/");
      });
    } catch (error) {
      console.error("Error al cerrar sesión:", error);
      // Puedes manejar el error de alguna manera si lo deseas
    }
  }
  return (
    <header className="header-home">
      <Link to="/">
        <img className="home-svg" src={svg} alt="" />
      </Link>
      <nav className="nav-home" ref={navRef}>
        <div className="dropdown-home">
          <Link to="/">Inicio</Link>
        </div>

        <div className="dropdown-home">
          <Link to="#">Nosotros</Link>
        </div>

        <div className="dropdown-home">
          <Link to="#">Informacion</Link>
          <div className="dropdown-content-home">
            <Link to="/servicios">Costo de servicio</Link>
            <Link to="/">Guias de los tramites</Link>
            <Link to="#">Preguntas frecuentes</Link>
            <Link to="/cotizar">Cotizar</Link>
          </div>
        </div>

        <div className="dropdown-home">
          <Link to="/upload">Solicitud</Link>
          <div className="dropdown-content-home">
            <Link to="/table">Proceso del Tramite</Link>
            {correosPermitidos.includes(usuarioAutenticado?.email) && (
          <div className="dropdown-home">
            <Link to="/archivos">Archivos</Link>
          </div>
        )}
          </div>
        </div>

        <button className="nav-btn nav-close-btn" onClick={showNavbar}>
          <FaTimes />
        </button>
        <Link className="boton-pro">
        Perfil
        <div className="opcionespro">
          <button onClick={logOutHome}>Cerrar sesion</button>
        </div>
      </Link>
      </nav>

      <button className="nav-btn" onClick={showNavbar}>
        <FaBars />
      </button>
    </header>
  );
}

export default NavbarPro;
