import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Boton.css";
import gmail from "../svg/gmail.svg";
import instagram from "../svg/instagram.svg";
import whatsapp from "../svg/whatsapp.svg";
import chat from "../svg/chat.svg";
import linkedin from "../svg/Linkedin2.svg"; // Asegúrate de que la ruta sea correcta

function Boton() {
  const [mostrarCirculos, setMostrarCirculos] = useState(false);
  const [animandoCirculos, setAnimandoCirculos] = useState(false);

  const handleClick = () => {
    if (mostrarCirculos) {
      setAnimandoCirculos(true);
      setTimeout(() => {
        setMostrarCirculos(false);
        setAnimandoCirculos(false);
      }, 800); // Duración de la animación inversa + retraso
    } else {
      setMostrarCirculos(true);
    }
  };

  const handleCirculoClick = (url, isWhatsApp = false) => {
    if (isWhatsApp) {
      const whatsappUrl = `https://wa.me/${url}`;
      window.open(whatsappUrl, '_blank');
    } else {
      window.open(url, '_blank');
    }
  };

  return (
    <div className="contenedor-Boton">
      <button className="boton-Boton" onClick={handleClick}>
        <img className="svg-chat" src={chat} alt="Image" />
      </button>
      {mostrarCirculos && (
        <div
          className={`circulo-arriba ${animandoCirculos ? "desaparecer" : ""}`}
          onClick={() => handleCirculoClick("+50684532319", true)} // Reemplaza con el número de WhatsApp
        >
          <img className="svg-whatsapp" src={whatsapp} alt="Imagen" />
        </div>
      )}
      {mostrarCirculos && (
        <div
          className={`circulo-arriba1 ${animandoCirculos ? "desaparecer1" : ""}`}
          onClick={() => handleCirculoClick("https://www.instagram.com/blg025/")}
        >
          <img className="svg-instagram" src={instagram} alt="Imagen" />
        </div>
      )}
      {mostrarCirculos && (
        <Link to="/consultar">
          <div
            className={`circulo-arriba2 ${animandoCirculos ? "desaparecer2" : ""}`}
          >
            <img className="svg-gmail" src={gmail} alt="Imagen" />
          </div>
        </Link>
      )}
      {mostrarCirculos && (
        <div
          className={`circulo-arriba3 ${animandoCirculos ? "desaparecer3" : ""}`}
          onClick={() => handleCirculoClick("https://www.linkedin.com/company/volkancr/")} // Reemplaza con tu URL de LinkedIn
        >
          <img className="svg-linkedin" src={linkedin} alt="Imagen" />
        </div>
      )}
    </div>
  );
}

export default Boton;
