import React, { useState, useEffect } from "react";
import { auth } from "../../../../firebase/credenciales"; // Asegúrate de importar la instancia de autenticación desde tu archivo firebase.js
import "../quimicos.css";
import { Link } from "react-router-dom";

import Fondo from '../../img/BPM.jpg'
import ProductosQuimicos from "../componenteQuimico.jsx";



const App = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      setUser(authUser);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const descriptions = [
    { text: "Este paquete está diseñado para empresas que necesitan implementar desde cero un sistema de BPM para cumplir con la normativa en su sector (alimentos, farmacéuticos, cosméticos, etc.).", customClass: "" },
    { text: <strong>• Asesoría profesional para diseño de procesos según BPM</strong>, strongText: "", totalText:" (10 horas a $50/hora): $500", customClass: "textClass-Quimica" },
    { text: <strong>• Elaboración de manuales de BPM:</strong>, strongText: "", totalText:" $300", customClass: "textClass-Quimica" },
    { text: <strong>• Capacitación del personal en BPM</strong>, strongText: "", totalText: " (5 horas a $50/hora): $250", customClass: "textClass-Quimica" },
    { text: <strong>• Auditoría inicial de cumplimiento de BPM</strong>, strongText: "", totalText: "(2 visitas mensuales, $115/hora, 4 horas): $460", customClass: "textClass-Quimica" },
    { text: <strong>• Total paquete: $1,510 </strong>, strongText: "", totalText: "", customClass: "textClass-Quimica" },
    { text: <strong></strong>, strongText: "", totalText: "Nota: Esto es una sugerencia de paquete, podemos cotizar un servicio que se adapte a sus necesitas y que se adecue a su presupuesto", customClass: "texto-notas" }
  ];

  return (
    <ProductosQuimicos 
      backgroundImage={Fondo} 
      title="Implementación de Buenas Prácticas de Manufactura (BPM)" 
      buttonText="Regencia" 
      descriptions={descriptions}
      redirectTo="/BPM"
      imgClass="imgClass-BPM"
    />
  );
};


export default App;
