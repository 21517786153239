import React, { useState } from "react";
import '../css/login.css';
import { useNavigate } from "react-router-dom";
import resetPassword from "../../functions/resetPassword";
import svg from './svg/home.svg'
import {Link} from 'react-router-dom'
function ResetPassword() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await resetPassword(email);
      setMessage("Se ha enviado un correo para restablecer tu contraseña.");
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } catch (error) {
      setMessage("Error al enviar el correo de restablecimiento: " + error.message);
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };
  return (
    <div className="login-body">
      <Link className="login-svg-a" to="/">
        <img className="login-imagenEstilizada" src={svg} alt="" />
      </Link>
      <div className="login-container">
        <h1>Restablecer Contraseña</h1>
        <form className="login-form" onSubmit={handleSubmit}>
          <label className="login-label" htmlFor="email">Correo Electrónico:</label>
          <input
            className="login-input"
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <button className="login-button" type="submit">Enviar Correo</button>
        </form>
        <p>{message}</p>
      </div>
    </div>
  );
}

export default ResetPassword;
