import React, { useState, useEffect } from "react";
import { auth } from "../../../firebase/credenciales"; // Asegúrate de importar la instancia de autenticación desde tu archivo firebase.js
import "./quimicos.css";
import { Link } from "react-router-dom";

import Fondo from '../img/Plaguicidas.jpg'
import ProductosQuimicos from "./componenteQuimico.jsx";



const App = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      setUser(authUser);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const descriptions = [
    { text: <strong>Descripción:</strong>, strongText: "", totalText:" Los plaguicidas son sustancias químicas utilizadas para controlar plagas que afectan a los cultivos, la salud pública y otros entornos. Estos productos deben ser registrados y evaluados para asegurar que su uso no represente un riesgo para la salud humana y el medio ambiente.", customClass: "" },
    { text: <strong>Precio del Registro:</strong>, strongText: "", totalText: "$430", customClass: "textClass-Quimica" },
    { text: <strong></strong>, strongText: "", totalText: "Este precio incluye: ", customClass: "textClass-Quimica" },
    { text: "• Honorarios del profesional", strongText: "", totalText: "", customClass: "textClass-Quimica" },
    { text: "• Pago del registro ante el Ministerio de Salud", strongText: "", totalText: "", customClass: "textClass-Quimica" },
    { text: "• Pago de refrendo", strongText: "", totalText: "", customClass: "textClass-Quimica" },
    { text: "• Impuestos de valor agregado", strongText: "", totalText: "", customClass: "textClass-Quimica" }
  ];

  return (
    <ProductosQuimicos 
      backgroundImage={Fondo} 
      title="Registro de productos Plaguicidas" 
      buttonText="Volver a costos y servicios" 
      descriptions={descriptions}
    />
  );
};


export default App;
