import {auth} from "../firebase/credenciales.js";
import {createUserWithEmailAndPassword} from "firebase/auth";

export default async function registerUser(email, password){
    try{
        await createUserWithEmailAndPassword(auth, email, password);
        return true;
    } catch (error){
        console.log(error);
        return false;
    }
}   