import { auth } from "../firebase/credenciales";
import { GoogleAuthProvider, signInWithRedirect, signInWithPopup } from "firebase/auth";

const provider = new GoogleAuthProvider();

const loginWithGoogle = async () => {
  try {
    // Iniciar el proceso de inicio de sesión con Google
    await signInWithPopup(auth, provider);
  } catch (error) {
    console.error(error);
  }
};

export default loginWithGoogle;
