import React, { useState, useEffect } from "react";
import { auth } from "../../../../firebase/credenciales"; // Asegúrate de importar la instancia de autenticación desde tu archivo firebase.js
import "../quimicos.css";
import { Link } from "react-router-dom";

import Fondo from '../../img/BPM.jpg'
import ProductosQuimicos from "../componenteQuimico.jsx";



const App = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      setUser(authUser);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const descriptions = [
    { text: "Este paquete se enfoca en la gestión de calidad y seguridad en el marco de las BPM, ayudando a asegurar que los productos sean fabricados en condiciones seguras y cumpliendo con los estándares de calidad.", customClass: "" },
    { text: <strong>• Diseño y optimización de procesos de control de calidad</strong>, strongText: "", totalText:" (6 horas a $50/hora): $300", customClass: "textClass-Quimica" },
    { text: <strong>• Monitoreo de calidad en línea de producción:</strong>, strongText: "", totalText:" (4 horas mensuales a $115/hora): $460", customClass: "textClass-Quimica" },
    { text: <strong>• Elaboración de reportes operacionales de calidad y seguridad:</strong>, strongText: "", totalText: " $90 ", customClass: "textClass-Quimica" },
    { text: <strong>• Auditoría de seguridad industrial y salud ocupacional</strong>, strongText: "", totalText: " (3 horas a $115/hora): $345", customClass: "textClass-Quimica" },
    { text: <strong>• Total paquete: $1,915</strong>, strongText: "", totalText: "", customClass: "textClass-Quimica" },
    { text: <strong></strong>, strongText: "", totalText: "Nota: Esto es una sugerencia de paquete, podemos cotizar un servicio que se adapte a sus necesitas y que se adecue a su presupuesto", customClass: "texto-notas" }
  ];

  return (
    <ProductosQuimicos 
      backgroundImage={Fondo} 
      title="Control de Calidad y Seguridad en BPM" 
      buttonText="Regencia" 
      descriptions={descriptions}
      redirectTo="/BPM"
      imgClass="imgClass-BPM"
    />
  );
};


export default App;
