import { useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./NavBar.css";
import svg from "../svg/logo.svg"
import Boton from "./Boton"

function Navbar() {
	const navRef = useRef();

	const showNavbar = () => {
		navRef.current.classList.toggle(
			"responsive_nav"
		);
	};

	return (
		<header className="header-home">
			<Link to="/">
        <img className="home-svg" src={svg} alt="" />
      </Link>
			<nav className="nav-home" ref={navRef}>
            <div className="dropdown-home">
            	<Link to="/">Inicio</Link>
          	</div>

          <div className="dropdown-home">
            <Link to="/nosotros">Nosotros</Link>
          </div>

          <div className="dropdown-home">
            <Link className="tipoInformacion" to="#">Información</Link>
            <div className="dropdown-content-home">
            	<Link to="/servicios">Costo de servicio</Link>
            	<Link to="/guiatramites">Guías de los servicios</Link>
            	<Link to="/question">Preguntas frecuentes</Link>
            	<Link to="/cotizar">Cotizar</Link>
            	<Link to="/upload">Calculadora de costos</Link>
            </div>
          </div>
				<button
					className="nav-btn nav-close-btn"
					onClick={showNavbar}>
					<FaTimes />
				</button>
                <Link className="boton-home" to="/login">
          			Iniciar sesión
        		</Link>
			</nav>
            
			<button
				className="nav-btn"
				onClick={showNavbar}>
				<FaBars />
			</button>
		</header>
	);
}

export default Navbar;
