import React, { useState, useEffect, useRef } from "react";
import NavbarLite from "./Components/NavBarLite";
import "../css/question.css";
import InfoComponent from "../jsx/Components/InfoComponent.jsx";

const RegistroSanitario = [
  {
    subtitle: "1. Evaluación del producto",
    text: "El primer paso es analizar el producto que deseas registrar. Dependiendo si es un alimento, un medicamento, un cosmético, un suplemento alimenticio, o un dispositivo médico, los requisitos y el proceso pueden variar. Este análisis nos permitirá determinar qué documentación necesitas y cuál es la entidad reguladora correspondiente. Por ejemplo, si es un medicamento, lo regulamos ante el Ministerio de Salud."
  },
  {
    subtitle: "2. Recopilación de la documentación",
    text: "Cada tipo de producto tiene requisitos específicos. Esto puede incluir la composición del producto, sus etiquetas, certificados de fabricación y, si es importado, certificados de libre venta del país de origen. También puede ser necesario proporcionar estudios de seguridad, estabilidad o eficacia, dependiendo del producto. Yo me encargaría de guiarte en la recolección de todos estos documentos, asegurándome de que todo esté en orden."
  },
  {
    subtitle: "3. Presentación de la solicitud",
    text: "Una vez tengamos todo listo, presentamos los documentos ante la entidad reguladora correspondiente, por ejemplo, el Ministerio de Salud. En esta etapa también se realiza el pago de las tarifas asociadas al proceso de registro. Yo me ocuparía de gestionar esta parte administrativa por ti."
  },
  {
    subtitle: "4. Evaluación técnica",
    text: "Después de presentar la solicitud, el producto entra en una fase de revisión por parte de las autoridades. Ellos evaluarán la documentación y, en algunos casos, pueden solicitar pruebas adicionales o inspecciones. Yo me mantendré en contacto con las autoridades para asegurarnos de que cualquier observación o solicitud adicional se maneje de manera rápida y eficiente."
  },
  {
    subtitle: "5. Resolución y aprobación",
    text: "Si todo está en orden, se otorgará el registro sanitario o permiso que te permitirá comercializar tu producto en Costa Rica. El tiempo de aprobación varía según el tipo de producto, pero estaré en contacto durante todo el proceso para mantenerte informado de los avances."
  },
  {
    subtitle: "6. Renovación y cumplimiento",
    text: "En muchos casos, los registros tienen una duración limitada, normalmente entre 5 y 10 años, dependiendo del tipo de producto. Además, es importante mantener actualizados ciertos documentos como las etiquetas o las fórmulas. Yo me encargaría de recordarte cuándo y cómo realizar la renovación, asegurándome de que tu producto siempre cumpla con la normativa vigente."
  },
  {
    subtitle: "Mi rol como químico sería:",
    text: "Guiarte en cada paso, asegurándome de que todos los requisitos sean claros. Asegurarme de que los documentos cumplen con los estándares que exige la normativa costarricense.Gestionar el proceso con las autoridades y resolver cualquier duda o solicitud adicional que surja. Mi objetivo es hacer que este proceso sea lo más fluido y sencillo posible para ti, y que tu producto esté registrado correctamente y a tiempo.Elaboración de Hojas de Seguridad: Proceso para crear una hoja conforme a la normativa."
  },
]

const tramiteBPM = [
  {
    subtitle: "1. Supervisión y Control de Procesos",
    text: "El regente químico debe supervisar los procesos industriales, asegurando que se realicen de manera segura y cumpliendo con la normativa vigente. Esto incluye procesos de fabricación, almacenamiento y manejo de sustancias químicas."
  },
  {
    subtitle: "2. Garantía de Cumplimiento Normativo",
    text: "Asegura que la industria cumpla con todas las regulaciones legales en materia de salud, seguridad y medio ambiente. Esto puede incluir la revisión de documentos como fichas técnicas, certificados de seguridad y otros informes relacionados con productos químicos"
  },
  {
    subtitle: "3. Elaboración de Documentos Técnicos",
    text: "El regente químico es responsable de la elaboración de documentos como fichas de datos de seguridad (FDS), fichas de emergencia para el transporte de materiales peligrosos, y etiquetas según el Sistema Globalmente Armonizado (SGA). Estos documentos son fundamentales para la legalidad de los productos químicos"
  },
  {
    subtitle: "4. Auditorías y Reportes",
    text: "Realiza auditorías de seguridad, salud ocupacional y protección ambiental. Además, genera reportes operacionales, como aquellos sobre emisiones de contaminantes atmosféricos, residuos peligrosos y agua potable"
  },
  {
    subtitle: "5. Asesoría Técnica",
    text: "Proporciona asesoría técnica a empresas en temas relacionados con la producción, distribución y manejo de productos químicos, incluyendo la implementación de sistemas de control de calidad y seguridad "
  },
  {
    subtitle: "6. Firma de Documentos Oficiales",
    text: "Como parte de su responsabilidad profesional, el regente químico firma y sella documentos oficiales que certifican la seguridad y el cumplimiento técnico de procesos y productos dentro de su área de competencia "
  },
]

const tramiteBPM2 = [
  {
    subtitle: "",
    text: "Una auditoría en Buenas Prácticas de Manufactura (BPM) se realiza para evaluar el cumplimiento de una empresa o proceso productivo con los estándares establecidos para asegurar la calidad y seguridad en la producción. Estas auditorías permiten identificar áreas de mejora y asegurar que los productos fabricados cumplan con los requisitos legales y de calidad. A continuación, te detallo los pasos clave para realizar una auditoría en BPM: "
  },
  {
    subtitle: "1. Planificación de la Auditoría",
    text: "• Definir el alcance: Se debe determinar qué áreas, procesos o productos de la empresa serán auditados. Esto puede incluir la planta de producción, los sistemas de calidad, control de residuos, limpieza y mantenimiento, etc. "
  },
  {
    subtitle: "",
    text: "• Revisar la normativa: Asegurarse de que la auditoría estará basada en las regulaciones específicas del sector (alimentos, farmacéuticos, cosméticos, etc.) y las BPM aplicables. "
  },
  {
    subtitle: "",
    text: "• Asignar al equipo auditor: Se seleccionan los auditores que llevarán a cabo la auditoría. Pueden ser internos (propios de la empresa) o externos (auditores de terceros, como el regente químico o consultores especializados). "
  },
  {
    subtitle: "2. Revisión de la Documentación ",
    text: "Antes de la visita, se debe revisar toda la documentación relevante, como: "
  },
  {
    subtitle: "",
    text: "• Manuales de procedimientos operativos."
  },
  {
    subtitle: "",
    text: "• Registros de producción."
  },
  {
    subtitle: "",
    text: "• Informes de control de calidad."
  },
  {
    subtitle: "",
    text: "• Planes de limpieza y mantenimiento. "
  },
  {
    subtitle: "",
    text: "• Fichas técnicas de productos y materias primas."
  },
  {
    subtitle: "",
    text: "• Certificados de cumplimiento previo (si existen). "
  },
  {
    subtitle: "",
    text: "El auditor verifica que la documentación esté completa, actualizada y que refleje las actividades reales de la empresa. "
  },
  {
    subtitle: "3. Visita de Inspección a las Instalaciones ",
    text: "Durante la visita, se inspecciona la planta o área productiva para verificar: "
  },
  {
    subtitle: "",
    text: "• Condiciones de infraestructura: Incluyendo la limpieza, organización, control de plagas, mantenimiento de equipos y condiciones del entorno. "
  },
  {
    subtitle: "",
    text: "• Flujo de trabajo: Se asegura que el proceso de producción sigue un flujo lógico y que las áreas críticas (almacenamiento de materias primas, producción y empaquetado) están bien controladas."
  },
  {
    subtitle: "",
    text: "• Manejo de materias primas y productos terminados: Asegurarse de que las materias primas estén bien almacenadas y controladas, y que los productos terminados cumplan con las especificaciones de calidad."
  },
  {
    subtitle: "",
    text: "• Seguridad y salud: Evaluar las medidas de seguridad y salud ocupacional, como el uso de equipos de protección personal, controles de seguridad en maquinaria y manejo de residuos peligrosos."
  },
  {
    subtitle: "",
    text: "• Condiciones higiénicas: Verificar el cumplimiento de los planes de limpieza y sanitización en todas las áreas de la planta."
  },
  {
    subtitle: "4. Entrevistas al Personal ",
    text: "El auditor realiza entrevistas con el personal clave para: "
  },
  {
    subtitle: "",
    text: "• Verificar que el personal conoce y sigue los procedimientos operativos estándar (POE)."
  },
  {
    subtitle: "",
    text: "• Evaluar la capacitación continua del personal en temas de BPM, seguridad, y control de calidad."
  },
  {
    subtitle: "",
    text: "• Obtener información sobre posibles problemas o mejoras que no se reflejan en la documentación."
  },
  {
    subtitle: "5. Toma de Muestras (Opcional) ",
    text: "Dependiendo del alcance de la auditoría, se pueden tomar muestras de productos o materias primas para realizar análisis de calidad en laboratorio. Esto ayuda a validar que el producto cumple con las especificaciones técnicas y legales."
  },
  {
    subtitle: "6. Evaluación de No Conformidades",
    text: "• El auditor identifica las no conformidades, que son los incumplimientos o desviaciones detectadas en los procesos respecto a las BPM. "
  },
  {
    subtitle: "",
    text: "• Estas no conformidades se clasifican generalmente en:"
  },
  {
    subtitle: "",
    text: "○ Críticas: Si pueden afectar gravemente la seguridad o calidad del producto."
  },
  {
    subtitle: "",
    text: "○ Mayores: Si comprometen el sistema de gestión de calidad, pero no suponen un riesgo inmediato."
  },
  {
    subtitle: "",
    text: "○ Menores: Si son pequeñas desviaciones que no afectan la calidad o seguridad de los productos."
  },
  {
    subtitle: "7. Elaboración del Informe de Auditoría",
    text: "Después de la inspección, el auditor redacta un informe detallado que incluye: "
  },
  {
    subtitle: "",
    text: "• Un resumen de los hallazgos."
  },
  {
    subtitle: "",
    text: "• Las no conformidades detectadas."
  },
  {
    subtitle: "",
    text: "• Las recomendaciones para corregir dichas no conformidades."
  },
  {
    subtitle: "",
    text: "• Un plan de acción con plazos para implementar las mejoras necesarias."
  },
  {
    subtitle: "",
    text: "Este informe se entrega a la dirección de la empresa para que tomen las acciones correctivas correspondientes."
  },
  {
    subtitle: "8. Seguimiento de Acciones Correctivas",
    text: "•     Se establece un plan de acción para corregir las no conformidades identificadas. "
  },
  {
    subtitle: "",
    text: "•     El auditor puede realizar auditorías de seguimiento para asegurarse de que las medidas correctivas se implementaron correctamente. "
  },
  {
    subtitle: "",
    text: "•     Si todo se resuelve adecuadamente, se emite un certificado de conformidad con las BPM. "
  },
  {
    subtitle: "9. Elementos Clave a Revisar en una Auditoría de BPM:",
    text: "•    Instalaciones: Cumplimiento con la limpieza, diseño y mantenimiento de equipos. "
  },
  {
    subtitle: "",
    text: "•     Personal: Capacitación y uso adecuado de equipos de protección personal. "
  },
  {
    subtitle: "",
    text: "•     Documentación: Procedimientos escritos, registros de producción y control de calidad. "
  },
  {
    subtitle: "",
    text: "•     Control de Proceso: Monitoreo adecuado de los puntos críticos de control (PCC). "
  },
  {
    subtitle: "",
    text: "•     Gestión de Residuos: Disposición adecuada de residuos peligrosos y no peligrosos. "
  },
  {
    subtitle: "",
    text: "•     Manejo de productos: Almacenamiento correcto y condiciones sanitarias para materias primas y productos terminados. "
  },
  {
    subtitle: "",
    text: "Una auditoría en BPM es un proceso detallado que permite a la empresa mejorar continuamente y garantizar que sus procesos cumplen con los más altos estándares de calidad y seguridad, tanto para sus trabajadores como para los productos que fabrica. "
  }
]
const App = () => {
  return (
    <div className="body-question">
      <NavbarLite />
      <InfoComponent 
        title="¿Como hacer un Registro Sanitario con nosotros?" 
        description={RegistroSanitario}
      />
      <InfoComponent 
        title="Funciones principales de un regente químico" 
        description={tramiteBPM}
      />
      <InfoComponent 
        title="¿Qué es una auditoría en Buenas Prácticas de Manufactura (BPM)?" 
        description={tramiteBPM2}
      />
    </div>
  );
};

export default App;
