import React, { useState, useEffect } from "react";
import { auth } from "../../../../firebase/credenciales"; // Asegúrate de importar la instancia de autenticación desde tu archivo firebase.js
import "../quimicos.css";
import { Link } from "react-router-dom";

import Fondo from '../../img/Regencia.jpg'
import ProductosQuimicos from "../componenteQuimico.jsx";



const App = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      setUser(authUser);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const descriptions = [
    { text: "Incluye la supervisión de procesos químicos en una industria, visitas semanales y asesoría profesional básica.", customClass: "" },
    { text: <strong>• 4 horas de regencia mensual ($115/hora):</strong>, strongText: "", totalText:"$460", customClass: "textClass-Quimica" },
    { text: <strong>• Asesoría profesional adicional</strong>, strongText: "", totalText:" (2 horas, $50/hora): $100", customClass: "textClass-Quimica" },
    { text: <strong>• Total paquete: $560</strong>, strongText: "", totalText: "", customClass: "textClass-Quimica" },
    { text: <strong></strong>, strongText: "", totalText: "Notas:", customClass: "texto-notas" },
    { text: <strong></strong>, strongText: "", totalText: " Esto es una sugerencia de paquete, podemos cotizar un servicio que se adapte a sus necesitas y que se adecue a su presupuesto", customClass: "texto-notas" },
    { text: <strong></strong>, strongText: "", totalText: "Precio no incluye inscripción de la empresa al colegio respectivo ni la apertura de bitácora de regencia ante el colegio", customClass: "texto-notas" }
  ];

  return (
    <ProductosQuimicos 
      backgroundImage={Fondo} 
      title="Regencia Básica Mensual" 
      buttonText="Regencia" 
      descriptions={descriptions}
      redirectTo="/regencia"
      imgClass="imgClass-RBM"
    />
  );
};


export default App;
