import React, { useState, useEffect, useRef } from "react";
import NavbarLite from "./Components/NavBarLite";
import "../css/question.css";
import InfoComponent from "../jsx/Components/InfoComponent.jsx";

const test = [
  {
    subtitle: "",
    text: ""
  },
]

const pregunta1 = [
  {
    subtitle: "",
    text: "Un regente químico es un profesional responsable de supervisar y asegurar que las operaciones de una industria que maneja productos químicos o procesos industriales cumplan con las normativas legales y de calidad. Es necesario para asegurar el cumplimiento de regulaciones como las Buenas Prácticas de Manufactura (BPM), el manejo seguro de sustancias químicas, y la correcta gestión de procesos, lo que protege a la empresa de sanciones legales y garantiza la seguridad de sus productos. "
  },
]

const pregunta2 = [
  {
    subtitle: "",
    text: "El costo por hora de un regente químico es de $115 según las tarifas sugeridas por el Colegio de Químicos de Costa Rica. Dependiendo de las necesidades de la empresa, se pueden armar paquetes que incluyen horas de regencia, asesoría técnica, registros sanitarios y auditorías internas. Un paquete básico puede comenzar desde $560 mensuales. "
  },
]

const pregunta3 = [
  {
    subtitle: "Los servicios incluyen:",
    text: "Supervisión de procesos industriales para asegurar el cumplimiento de BPM. "
  },
  {
    subtitle: "",
    text: "Revisión de documentación como fichas de seguridad, etiquetado, y reportes operacionales. "
  },
  {
    subtitle: "",
    text: "Elaboración de fichas de emergencia para el manejo de materiales peligrosos. "
  },
  {
    subtitle: "",
    text: "Asesoría técnica en la gestión de residuos y la optimización de procesos industriales. "
  },
  {
    subtitle: "",
    text: "Firma de documentos oficiales como registros sanitarios o auditorías para certificar el cumplimiento legal. "
  },
]

const pregunta4 = [
  {
    subtitle: "Una auditoría de BPM es un proceso para evaluar si los sistemas de producción de una empresa cumplen con las normas de calidad y seguridad establecidas por la ley. Es importante porque: ",
    text: "Asegura que los productos se fabriquen en condiciones higiénicas."
  },
  {
    subtitle: "",
    text: "Garantiza la seguridad de los empleados y consumidores."
  },
  {
    subtitle: "",
    text: "Cumple con las regulaciones del sector, evitando sanciones."
  },
  {
    subtitle: "",
    text: "Mejora la reputación de la empresa al producir productos de alta calidad."
  },
]

const pregunta5 = [
  {
    subtitle: "Una auditoría en BPM implica los siguientes pasos: ",
    text: "Planificación: Definir el alcance y áreas a auditar. "
  },
  {
    subtitle: "",
    text: "Revisión de documentación: Evaluar los manuales operativos y registros de calidad. "
  },
  {
    subtitle: "",
    text: "Visita a las instalaciones: Verificar la infraestructura, procesos y condiciones higiénicas."
  },
  {
    subtitle: "",
    text: "Entrevistas al personal: Asegurarse de que los empleados siguen los procedimientos adecuados."
  },
  {
    subtitle: "",
    text: "Informe de auditoría: Identificación de no conformidades y recomendaciones para corregirlas."
  },
  {
    subtitle: "",
    text: "Seguimiento: Verificar que las acciones correctivas se hayan implementado correctamente. "
  },
]

const pregunta6 = [
  {
    subtitle: "Los costos varían según el paquete de servicios necesarios. Un ejemplo de paquete básico para implementar BPM podría incluir: ",
    text: "Asesoría profesional (10 horas a $50/hora): $500 "
  },
  {
    subtitle: "",
    text: "Capacitación de personal: $250"
  },
  {
    subtitle: "",
    text: "Auditoría inicial (4 horas a $115/hora): $460"
  },
  {
    subtitle: "",
    text: "Total: $1,210 "
  },
  {
    subtitle: "Para empresas que ya tienen BPM implementadas, el costo de una auditoría mensual de seguimiento puede comenzar en $960.",
    text: ""
  },
]

const pregunta7 = [
  {
    subtitle: "Las no conformidades más comunes que se pueden detectar durante una auditoría de BPM incluyen: ",
    text: "Falta de limpieza o mantenimiento adecuado de las instalaciones. "
  },
  {
    subtitle: "",
    text: "Documentación incompleta o desactualizada de procesos operativos."
  },
  {
    subtitle: "",
    text: "Ausencia de capacitación regular del personal en temas de seguridad e higiene."
  },
  {
    subtitle: "",
    text: "Manejo incorrecto de materias primas y productos terminados."
  },
  {
    subtitle: "",
    text: "Falta de medidas adecuadas de control de calidad durante el proceso de producción."
  },
]

const pregunta8 = [
  {
    subtitle: "Para cumplir con las BPM, tu empresa debe contar con: ",
    text: "Manual de procedimientos operativos estándar (POE). "
  },
  {
    subtitle: "",
    text: "Registros de producción y control de calidad."
  },
  {
    subtitle: "",
    text: "Fichas de datos de seguridad (FDS) de los productos químicos utilizados."
  },
  {
    subtitle: "",
    text: "Fichas de emergencia para el transporte y manejo de materiales peligrosos."
  },

  {
    subtitle: "",
    text: "Certificados de no peligrosidad de los productos, si aplicable."
  },
  {
    subtitle: "",
    text: "Planes de limpieza, mantenimiento y control de plagas."
  },
]

const pregunta9 = [
  {
    subtitle: "Contar con un regente químico trae diversas ventajas: ",
    text: "Cumplimiento legal: Evita sanciones o problemas legales relacionados con el manejo de productos químicos."
  },
  {
    subtitle: "",
    text: "Mejora de la calidad: Garantiza que los productos cumplan con los estándares de seguridad y calidad."
  },
  {
    subtitle: "",
    text: "Protección ambiental: Asegura la correcta gestión de residuos peligrosos y la minimización del impacto ambiental."
  },
  {
    subtitle: "",
    text: "Seguridad: Reduce los riesgos asociados al manejo de sustancias peligrosas, protegiendo tanto a los empleados como a los consumidores."
  },
]

const pregunta10 = [
  {
    subtitle: "El regente químico puede ofrecer capacitaciones en diversas áreas, como: ",
    text: "Buenas Prácticas de Manufactura (BPM): Asegurando que todo el personal siga los procedimientos correctos."
  },
  {
    subtitle: "",
    text: "Manejo seguro de productos químicos: Uso adecuado de equipos de protección personal, almacenamiento y manipulación."
  },
  {
    subtitle: "",
    text: "Control de calidad: Capacitación sobre el monitoreo de procesos críticos y toma de decisiones basadas en parámetros de calidad."
  },
  {
    subtitle: "",
    text: "Gestión de residuos peligrosos: Manejo, transporte y disposición de residuos industriales conforme a la normativa."
  },
]

const App = () => {
  return (
    <div class="body-question">
      <NavbarLite />
      <InfoComponent 
        title="1. ¿Qué es un regente químico y por qué lo necesito en mi empresa?" 
        description={pregunta1}
      />
      <InfoComponent 
        title="2. ¿Cuánto cuesta contratar un regente químico? " 
        description={pregunta2}
      />
      <InfoComponent 
        title="3. ¿Qué servicios ofrece un regente químico durante la supervisión?" 
        description={pregunta3}
      />
      <InfoComponent 
        title="4. ¿Qué es una auditoría de Buenas Prácticas de Manufactura (BPM) y por qué es importante? " 
        description={pregunta4}
      />
      <InfoComponent 
        title="5. ¿Cómo se realiza una auditoría en BPM?" 
        description={pregunta5}
      />
      <InfoComponent 
        title="6. ¿Qué costos puede esperar una empresa al implementar o auditar BPM?" 
        description={pregunta6}
      />
      <InfoComponent 
        title="7. ¿Cuáles son las principales no conformidades que una auditoría BPM podría identificar?" 
        description={pregunta7}
      />
      <InfoComponent 
        title="8. ¿Cuáles son las principales no conformidades que una auditoría BPM podría identificar?" 
        description={pregunta8}
      />
      <InfoComponent 
        title="9. ¿Qué ventajas ofrece tener a un regente químico en la empresa?" 
        description={pregunta9}
      />
      <InfoComponent 
        title="10. ¿Qué tipo de capacitación ofrece un regente químico a mi equipo?" 
        description={pregunta10}
      />
    </div>
  );
};

export default App;
