// credenciales.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

export const firebaseConfig = {
  apiKey: "AIzaSyAT7-ySw12LMehTierIATf9x5ouDu0seOM",
  authDomain: "registro-quimica.firebaseapp.com",
  databaseURL: "https://registro-quimica-default-rtdb.firebaseio.com",
  projectId: "registro-quimica",
  storageBucket: "registro-quimica.appspot.com",
  messagingSenderId: "173316526210",
  appId: "1:173316526210:web:2c65b3c7e9903e8490a49b",
  measurementId: "G-HZ9877QD9K"
};



const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app);

export { app, auth, storage };
