import React from 'react';
import { useNavigate } from 'react-router-dom';
import './BotonCorreo.css';

const BotonNuevo = ({ buttonText, className = '' }) => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate('/cotizar'); // Redirige a /cotizar
  };

  return (
    <button className={`boton-nuevo ${className}`} onClick={handleNavigation}>
      {buttonText}
    </button>
  );
};

export default BotonNuevo;
