import React, { useEffect, useState } from "react";
import '../css/login.css';
import svg from './svg/home.svg'
import { Link, useNavigate } from "react-router-dom";
import loginWithEmailAndPassword from "../../functions/loginUser";
import loginWithGoogle from '../../functions/loginWithGoogle';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Login() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    document.body.classList.add("login-body");
    return () => {
      document.body.classList.remove("login-body");
    };
  }, []);

  async function submitHandler(e) {
    e.preventDefault();
    const username = e.target.username.value;
    const password = e.target.password.value;

    try {
      await loginWithEmailAndPassword(username, password);
      // Si no hay errores, redirige a la página principal
      navigate('/');
    } catch (error) {
      console.error('Error al iniciar sesión:', error.message);
      // Puedes manejar el error según tus necesidades
      var errorMessageElement = document.getElementById('error-message');
      // Actualizar el contenido del elemento con el mensaje de error
      errorMessageElement.textContent = error.message;
    }
  }

  const handleLoginWithGoogle = async () => {
    try {
      await loginWithGoogle();
      // Después de iniciar sesión con Google, redirige a la página principal
      navigate('/');
    } catch (error) {
      console.error('Error al iniciar sesión con Google:', error);
      // Puedes manejar el error según tus necesidades
    }
  };

  return (
    <div className="login-body">
      <Link className="login-svg-a" to="/">
        <img className="login-imagenEstilizada" src={svg} alt="" />
      </Link>
      <div className="login-container">
        <h1>Iniciar Sesión</h1>
        <form className="login-form" onSubmit={submitHandler}>
          <label className="login-label" htmlFor="username">Usuario:</label>
          <input className="login-input" type="text" id="username" name="username" />

          <label className="login-label" htmlFor="password">Contraseña:</label>
          <div className="password-input-container">
          <input
            type={showPassword ? 'text' : 'password'}
            className="login-input"
            id="password"
            name="password"
            required
          />
          <FontAwesomeIcon
            icon={showPassword ? faEyeSlash : faEye}
            onClick={() => setShowPassword(!showPassword)}
            className="toggle-password-visibility"
          />
        </div>

          <button className="login-button" type="submit">Iniciar Sesión</button>
        </form>
        <p id="error-message"></p>
        <div style={{ textAlign: 'center' }}>
          <p className="login-p" style={{ display: 'inline' }}>¿No tienes una cuenta? </p>
          <Link className="login-a" to="/register" style={{ display: 'inline' }}>Regístrate aquí</Link>
        </div>
        <div style={{ textAlign: 'center', marginTop: '10px' }}>
          <Link className="login-a" to="/reset" style={{ display: 'inline' }}>¿Olvidaste tu contraseña?</Link>
        </div>
        <div className="bottonDiv">
        <button onClick={handleLoginWithGoogle} className="Botton-google-register">
          Accede con Google
        </button>
        </div>
      </div>
    </div>
  );
}

export default Login;
