import React, { useState, useEffect } from "react";
import { auth } from "../../../../firebase/credenciales";
import NavbarPro from "../../Components/NavBarPro";
import Navbar from "../../Components/NavBarLite";
import "./bpm.css";
import { Link, useLocation } from "react-router-dom"; // Importa useLocation
import Boton from '../../Components/Boton'

import Fondo from '../../img/BPM.jpg'

import { ReactComponent as SVGregresar } from "../../icon/newIcon/Regresar.svg";
import { ReactComponent as SVGbpm } from "../../icon/newIcon/BMP.svg";
import { ReactComponent as SVGmmbpm } from "../../icon/newIcon/Monitoreo y Mantenimiento de BPM.svg";
import { ReactComponent as SVGcBPMnp } from "../../icon/newIcon/Certificación de BPM para Nuevos Productos.svg";
import { ReactComponent as SVGar } from "../../icon/newIcon/Auditoría y Revalidación de BPM.svg";
import { ReactComponent as SVGccs } from "../../icon/newIcon/Control de Calidad y Seguridad en BPM.svg";
import { ReactComponent as SVGgirsa } from "../../icon/newIcon/Gestión Integral de Residuos y Seguridad Ambiental.svg";
import { ReactComponent as SVGcBPMpe } from "../../icon/newIcon/Consultoría en BPM para Pequeñas Empresas.svg";

const ServicioCard = ({ to, svg: SvgIcon, title }) => {
  return (
    <div className="box-regencia">
      <Link to={to} className="link-servicio">
        <SvgIcon className="svg-regencia" />
        <p>{title}</p>
      </Link>
    </div>
  );
};

const BPM = () => {
  const [user, setUser] = useState(null);
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const location = useLocation(); // Obtener la ubicación actual

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      setUser(authUser);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    // Verificar si la página anterior era "/servicios"
    if (location.state?.from === "/servicios") {
      setShowDisclaimer(false);
    }
  }, [location]);

  const handleDisclaimerClick = () => {
    setShowDisclaimer(false);
  };

  return (
    <div className="body-regencia">
      {user ? <NavbarPro /> : <Navbar />}
      <div className="contenedor-regencia">
        <div className="img-regencia">
          <img src={Fondo} alt="" className="fondo-regencia" />
          <h1>BPM</h1>
        </div>

        <div className="Cartas-regencia">
          <ServicioCard
            to="/servicios"
            svg={SVGregresar}
            title="Volver a costos y servicios"
          />

          <ServicioCard
            to="/BPMimplementacion"
            svg={SVGbpm}
            title="Implementación de Buenas Prácticas de Manufactura (BPM)"
          />

          <ServicioCard
            to="/BPMmonitoreo"
            svg={SVGmmbpm}
            title="Monitoreo y Mantenimiento de BPM"
          />

          <div></div>

          <ServicioCard
            to="/BPMcertificacion"
            svg={SVGcBPMnp}
            title="Certificación de BPM para Nuevos Productos"
          />

          
          <ServicioCard
            to="/BPMauditoria"
            svg={SVGar}
            title="Auditoría y Revalidación de BPM"
            />

            <div></div>
            <ServicioCard
            to="/BPMcalidadYseguridad"
            svg={SVGccs}
            title="Control de Calidad y Seguridad en BPM"
            />
            

            <ServicioCard
                to="/BPMgestionAmbiental"
                svg={SVGgirsa}
                title="Gestión Integral de Residuos y Seguridad Ambiental"
                />

                <div></div>
            <ServicioCard
                to="/BPMconsultoria"
                svg={SVGcBPMpe}
                title="Consultoría en BPM para Pequeñas Empresas "
            />


        </div>
      </div>

      {showDisclaimer && (
        <div className="disclaimer-popup">
          <div className="disclaimer-content">
            <h3>Aviso importante</h3>
            <p>
              Cada vez que alguien quiera ingresar a regencia conmigo tendré que hacer un contrato de mínimo un año... hay que poner una nota en ese botón que los costos son mensuales y que no incluyen costos de ingreso ni bitacora.
            </p>
            <button className="button_upload" onClick={handleDisclaimerClick}>
              Cerrar
            </button>
          </div>
        </div>
      )}
      <Boton />
    </div>
  );
};

export default BPM;
