import React, { useState, useEffect, useRef } from "react";
import NavbarPro from "./Components/NavBarPro";
import { getStorage, ref, listAll, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder } from "@fortawesome/free-solid-svg-icons";
import '../css/drive.css';

function Drive() {
  const [folders, setFolders] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const [fileToUpload, setFileToUpload] = useState(null);
  const fileInputRef = useRef(null);

  const fetchFolderContents = async (folder) => {
    try {
      const folderContent = await listAll(folder);
      setDocuments(folderContent.items);
    } catch (error) {
      console.error("Error al obtener el contenido de la carpeta:", error && error.message);
    }
  };

  const handleFolderClick = (folder) => {
    setCurrentFolder(folder);
    fetchFolderContents(folder);
    setCompanyName("");
  };

  const handleDownload = async (file) => {
    try {
      const downloadURL = await getDownloadURL(file);
      console.log("URL de descarga del archivo:", downloadURL);
      window.open(downloadURL);
    } catch (error) {
      console.error("Error al obtener la URL de descarga:", error && error.message);
    }
  };

  const handleGoToRoot = () => {
    setCurrentFolder(null);
    fetchFolderContents(ref(getStorage(), "Documentos"));
  };

  const handleFilterByName = (inputValue) => {
    setCompanyName(inputValue);
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    setFileToUpload(file);
  };

  const handleUpload = async () => {
    if (fileToUpload) {
      const storage = getStorage();
      const folderRef = currentFolder ? currentFolder : ref(storage, "Documentos");
      const fileRef = ref(folderRef, fileToUpload.name);

      try {
        await uploadBytesResumable(fileRef, fileToUpload);
        // Refrescar la lista de documentos después de la carga
        fetchFolderContents(folderRef);
        setFileToUpload(null);
        // Limpiar el campo de entrada de archivos
        fileInputRef.current.value = null;
      } catch (error) {
        console.error("Error al subir el archivo:", error && error.message);
      }
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFiles = e.dataTransfer.files;
    if (droppedFiles.length > 0) {
      setFileToUpload(droppedFiles[0]);
      handleUpload();
    }
  };

  const Document = ({ document }) => {
    const getFileType = (name) => {
      const extension = name.split('.').pop().toLowerCase();
      switch (extension) {
        case 'txt':
          return 'text-file';
        case 'pdf':
          return 'pdf-file';
        case 'doc':
        case 'docx':
          return 'word-file';
        case 'png':
        case 'jpg':
        case 'jpeg':
          return 'image-file';
        default:
          return 'default-file';
      }
    };

    const fileType = getFileType(document.name);

    return (
      <div
        key={document.fullPath}
        className={`document ${fileType}`}
        onClick={() => handleDownload(document)}
      >
        {document.name}
      </div>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const rootRef = ref(getStorage(), currentFolder ? currentFolder.fullPath : "Documentos");
        const rootList = await listAll(rootRef);
        const filteredFolders = rootList.prefixes.filter((folder) =>
          folder.name.toLowerCase().includes(companyName.toLowerCase())
        );
        setFolders(filteredFolders);
        setDocuments(rootList.items);
      } catch (error) {
        console.error("Error al obtener la lista de carpetas y documentos:", error && error.message);
      }
    };

    fetchData();
  }, [currentFolder, companyName]);

  useEffect(() => {
    document.body.classList.add("body2-drive");
    return () => {
      document.body.classList.remove("body2-drive");
    };
  }, []);

  return (
    <div>
      <NavbarPro />
      <div className="body-Drive">
        <div className="content-wrapper">
          <div
            className="folder-container"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <div className="folder-exit" onClick={handleGoToRoot}>
              Ir al inicio
            </div>
            <div>
              <label htmlFor="companyName">Filtro por palabra:</label>
              <input
                type="text"
                id="companyName"
                value={companyName}
                onChange={(e) => handleFilterByName(e.target.value)}
              />
            </div>
            <div className="flex-container">
              {folders.map((folder) => (
                folder ? (
                  <div
                    key={folder.fullPath}
                    className={`folder ${currentFolder && currentFolder.fullPath === folder.fullPath ? 'selected-folder' : ''}`}
                    onClick={() => handleFolderClick(folder)}
                  >
                    <div className="folder-icon">
                      <FontAwesomeIcon icon={faFolder} />
                    </div>
                    {folder.name}
                  </div>
                ) : null
              ))}
            </div>
          </div>
          <div className="documents-container">
            <h2>Documentos en la carpeta seleccionada:</h2>
            <div className="file-upload-container">
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileInputChange}
              />
              <button onClick={handleUpload}>Subir</button>
            </div>
            {documents.map((document) => (
              <Document key={document.fullPath} document={document} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Drive;
