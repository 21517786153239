import React, { useState, useEffect } from "react";
import { auth } from "../../../../firebase/credenciales"; // Asegúrate de importar la instancia de autenticación desde tu archivo firebase.js
import "../quimicos.css";
import { Link } from "react-router-dom";

import Fondo from '../../img/BPM.jpg'
import ProductosQuimicos from "../componenteQuimico.jsx";



const App = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      setUser(authUser);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const descriptions = [
    { text: "Paquete diseñado para empresas que desean lanzar productos nuevos en mercados donde se requiere la certificación de BPM.", customClass: "" },
    { text: <strong>• Registro sanitario de productos (alimentos, cosméticos, químicos):</strong>, strongText: "", totalText:" $150", customClass: "textClass-Quimica" },
    { text: <strong>• Revisión y aprobación de etiquetado conforme a BPM y SGA:</strong>, strongText: "", totalText:" $75", customClass: "textClass-Quimica" },
    { text: <strong>• Elaboración de ficha técnica y certificación de composición:</strong>, strongText: "", totalText: " $55", customClass: "textClass-Quimica" },
    { text: <strong>• Inspección y auditoría del proceso de manufactura para certificación BPM</strong>, strongText: "", totalText: " (3 visitas a $115/hora, 4 horas por visita): $1,380 ", customClass: "textClass-Quimica" },
    { text: <strong>• Total paquete: $1,660</strong>, strongText: "", totalText: "", customClass: "textClass-Quimica" },
    { text: <strong></strong>, strongText: "", totalText: "Nota: Esto es una sugerencia de paquete, podemos cotizar un servicio que se adapte a sus necesitas y que se adecue a su presupuesto", customClass: "texto-notas" }
  ];

  return (
    <ProductosQuimicos 
      backgroundImage={Fondo} 
      title="Certificación de BPM para Nuevos Productos" 
      buttonText="Regencia" 
      descriptions={descriptions}
      redirectTo="/BPM"
      imgClass="imgClass-BPM"
    />
  );
};


export default App;
