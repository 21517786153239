// Importa los módulos necesarios
import React, { useState, useEffect, useRef } from "react";
import Navbar from './Components/NavBarLite'
import Boton from './Components/Boton'
import "../css/home.css"
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";

//Imagenes
import fondoInicio from './img/Fondo-inicio.jpg';
// Definición del componente Home
function Home() {
  useEffect(() => {
    document.body.classList.add("body-home");
    return () => {
      document.body.classList.remove("body-home");
    };
  }, []);
  return (
    <div>
      <Helmet>
        <title>Volkan</title>
        <meta name="description" content="¿Como puedo registrar mis productos quimicos en costa rica?" />
      </Helmet>
      <Navbar />
      <div className="bodyImg">
        <img src={fondoInicio} alt="img" />
        <div className="titleInicio">
          <h1>Servicios Profesionales en Química</h1>
          <h2>Soluciones Químicas Integrales para tu Negocio</h2>
          <p>Ofrecemos servicios de registros sanitarios, elaboración de hojas de seguridad, y proyectos de etiquetado con un enfoque en la calidad y cumplimiento de normativas. Próximamente, también brindaremos revisión de sistemas GLP, regencia química externa, consultoría de formulaciones, fichas de transporte y más.</p>
          <Link className="linkServicios" to="/servicios">Conozca nuestro servicios</Link>
          
        </div>
      </div>
      <Boton></Boton>
    </div>
  );
}

// Exporta el componente Home
export default Home;
