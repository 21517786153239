import React, { useEffect, useState } from "react";
import '../css/register.css';
import svg from './svg/home.svg'
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import registerUser from '../../functions/registerUser'

function Register() {
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false);
  useEffect(() => {
    // Al montar el componente, agrega la clase al body
    document.body.classList.add("register-body");
    // Al desmontar el componente, quita la clase del body
    return () => {
      document.body.classList.remove("register-body");
    };
  }, []); // El array vacío asegura que el efecto se ejecute solo una vez al montar y desmontar el componente

  async function submitHandler(e){
    e.preventDefault();
    const username = e.target.username.value;
    const password = e.target.password.value;

    await registerUser(username,password)
    setTimeout(() => {
      navigate('/');
    });
  }


  return (
    <div className="register-body">
        <Link className="login-svg-a" to="/">
        <img className="login-imagenEstilizada" src={svg} alt="" />
        </Link>
      <div className="register-container">
        <h1>Registrarte</h1>
        <form className="register-form"
        onSubmit={submitHandler}>
          <label className="register-label" htmlFor="username">Usuario:</label>
          <input className="register-input" type="text" id="username" name="username" required/>

          <label className="register-label" htmlFor="password">Contraseña:</label>
          <div className="password-input-container">
          <input type={showPassword ? 'text' : 'password'} className="register-input" id="password" name="password" required/>
          <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} onClick={() => setShowPassword(!showPassword)} className="toggle-password-visibility" />
        </div>

          <button className="register-button" type="submit">Registrarte</button>
        </form>
        <div style={{ textAlign: 'center' }}>
          <p className="register-p" style={{ display: 'inline' }}>¿Ya tienes una cuenta? </p>
          <Link className="register-a" to="/login" style={{ display: 'inline' }}>Iniciar cuenta aquí</Link>
        </div>


      </div>
    </div>
  );
}

export default Register;
