import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { auth} from './firebase/credenciales';
import { onAuthStateChanged } from 'firebase/auth';
import { Helmet } from 'react-helmet-async';
import Home from './views/jsx/home';
import HomePro from './views/jsx/homepro';
import Login from './views/jsx/login';
import Register from './views/jsx/register';
import UploadFile from './views/jsx/UploadFile';
import PageTable from './views/jsx/Tabla';
import Drive from './views/jsx/GoogleDrive';
import Services from './views/jsx/Servicio';
import Cotizar from './views/jsx/cotizar';
import Error from './views/jsx/error';
import Boton from './views/jsx/Components/Boton';
import Consultar from './views/jsx/consulta';
import Sitemap from './views/sitemap.xml';
import ResetPassword from './views/jsx/resetpassword';
import ResetPasswordSite from './views/jsx/resetpasswordfirebase'
import Question from './views/jsx/question'
import Nosotros2 from './views/jsx/nosotros2'
import GuiaTramite from './views/jsx/guiaTramite'
// Dentro de /services
import ProductosQuimicos from './views/jsx/Servicios/quimico.jsx'
import ProductosHigienicos from './views/jsx/Servicios/higienicos.jsx'
import ProductosPlaguicidas from './views/jsx/Servicios/plaguicidas.jsx'
import ProductosAlimentos from './views/jsx/Servicios/alimentos.jsx'
import ProductosCosmeticos from './views/jsx/Servicios/cosmeticos.jsx'
// dentro de /regencia
import Regencia from './views/jsx/Servicios/REGENCIA/regencia.jsx'
import RegenciaCRNE from './views/jsx/Servicios/REGENCIA/CRNE.jsx'
import RegenciaRBM from './views/jsx/Servicios/REGENCIA/RBM.jsx'
import RegenciaRIQ from './views/jsx/Servicios/REGENCIA/RIQ.jsx'
import RegenciaRETMP from './views/jsx/Servicios/REGENCIA/RETMP.jsx'

//BPM
import BPM from './views/jsx/Servicios/BPM/bpm.jsx';
import ARBPM from './views/jsx/Servicios/BPM/ARBPM.jsx'
import CBPMPE from './views/jsx/Servicios/BPM/CBPMPE.jsx'
import CBPMNP from './views/jsx/Servicios/BPM/CBPMNP.jsx'
import CCSBPM from './views/jsx/Servicios/BPM/CCSBPM.jsx'
import GIRSABPM from './views/jsx/Servicios/BPM/GIRSABPM.jsx'
import IBPM from './views/jsx/Servicios/BPM/IBPM.jsx'
import MMBPM from './views/jsx/Servicios/BPM/MMBPM.jsx'

//PaginaGracias
import Gracias from './views/jsx/gracias.jsx'



import './App.css'
const App = () => {
  const [user, setUser] = useState(null);
  const [authLoading, setAuthLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (usuarioFirebase) => {
      if (usuarioFirebase) {
        setUser(usuarioFirebase);
      } else {
        setUser(null);
      }
      setAuthLoading(false);
    });

    return () => unsubscribe();
  }, []);

  // Espera hasta que la autenticación se haya completado antes de renderizar las rutas
  //<Route path='/upload' element={user ? <UploadFile user={user} /> : <Error />} />
  if (authLoading) {
    return <div>Cargando...</div>;
  }

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={user ? <HomePro user={user} /> : <Home />} />
          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Register />} />
          <Route path='/upload' element={<UploadFile/>} />
          <Route path='/table' element={user ? <PageTable user={user} /> : <Error />}></Route>
          <Route path='/archivos' element={user ? <Drive user={user} /> : <Error />} />
          <Route path='/servicios' element={<Services />} />
          <Route path='/cotizar' element={<Cotizar />} />
          <Route path='/consultar' element={<Consultar />} />
          <Route path='/sitemap.xml' element={<Sitemap />} />
          <Route path='/reset' element={<ResetPassword />} />
          <Route path='/resetPassword' element={<ResetPasswordSite/>}></Route>
          <Route path='/question' element={<Question/>}></Route>
          <Route path='/nosotros' element={<Nosotros2/>}></Route>
          <Route path='/guiatramites' element={<GuiaTramite/>}></Route>

          <Route path='/productosquimicos' element={<ProductosQuimicos/>}></Route>
          <Route path='/productoshigienicos' element={<ProductosHigienicos/>}></Route>
          <Route path='/productosplaguicidas' element={<ProductosPlaguicidas/>}></Route>
          <Route path='/productosalimentos' element={<ProductosAlimentos/>}></Route>
          <Route path='/productoscosmeticos' element={<ProductosCosmeticos/>}></Route>

          <Route path='/regencia' element={<Regencia/>}></Route>
          <Route path='/regenciaCRNE' element={<RegenciaCRNE/>}></Route>
          <Route path='/regenciaRBM' element={<RegenciaRBM/>}></Route>
          <Route path='/regenciaRETMP' element={<RegenciaRETMP/>}></Route>
          <Route path='/regenciaRIQ' element={<RegenciaRIQ/>}></Route>

          <Route path='/BPM' element={<BPM/>}></Route>
          <Route path='/BPMauditoria' element={<ARBPM/>}></Route>
          <Route path='/BPMconsultoria' element={<CBPMPE/>}></Route>
          <Route path='/BPMcertificacion' element={<CBPMNP/>}></Route>
          <Route path='/BPMcalidadYseguridad' element={<CCSBPM/>}></Route>
          <Route path='/BPMgestionAmbiental' element={<GIRSABPM/>}></Route>
          <Route path='/BPMimplementacion' element={<IBPM/>}></Route>
          <Route path='/BPMmonitoreo' element={<MMBPM/>}></Route>

          <Route path='/graciaspage' element={<Gracias/>}></Route>
          
          <Route path='*' element={<Error />} />
        </Routes>
        <div className='contenedor-boton'>
        <Boton></Boton>
      </div>
      </BrowserRouter>
    </div>
  );
};

export default App;
